import { Container } from "@material-ui/core";

const ContactView = () => {
    return (
        <Container style={{ marginTop: 100 }}>
            @setoelkahfi
        </Container>
    );
};

export default ContactView;