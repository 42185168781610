import { Container } from "@material-ui/core";

const AboutView = () => {
    return (
        <Container style={{ marginTop: 100 }}>
            NFT is a non-fungible token. This is the NFT marketplace of the Musik88 platform.
        </Container>
    );
};

export default AboutView;