import { Container, Typography } from "@material-ui/core";

const Home = () => {
    return (
        <Container style={{ marginTop: 100 }}>
            <Typography variant="h2" color="textSecondary">
             Welcome to the NFT marketplace by Musik88
            </Typography>
        </Container>
    );
};

export default Home;